import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ip from 'ip'
import Layout from '../../components/layout'
import Switch from "react-switch"


import {
	Container,
	Section,
	Row,
	Column,
	ScrollableContainer,
} from '../../components/layouts'

import {
	CardForm,
	FormProgress,
	Title,
	CheckBox,
	Paragraph,
	BtnField,
	Box,
	Checkout,
	Link
} from '../../components/ui'

import {
	clearErrors,
	submitOrgRequest,
	getCategories
} from '../../factory'

import theme from '../../theme/theme'
import stripePlans from '../../theme/stripePlans'
import { getUrlParams } from '../../util'


const {
	oneTimeDonation, whatOffer
} = theme.sections

const recaptchaRef = React.createRef()

// TODO: Specific error for page
class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			position: 0,
			valid: false,
			errorP1: '',
			errorP2: '',
			errorP3: '',
			errorP4: '',
			errorP5: '',
			success: false,
			pending: false,
			consent: {
				trial_agree: false,
				processing_agree: false,
				authority_agree: false,
				admin_ip: ip.address()
			},
			admin: {
				f_name: '',
				l_name: '',
				email: '',
				phone: ''
			},
			organization: {
				address: {},
				title: '',
				phone: '',
				email: '',
				website: '',
				category_id: -1
			},
			products: {
				app: false,
				analytics: false,
				oneClick: false,
				marketing: false,
				annual: false,
				monthly: false
			},
			firstPress: false,
			price: 1
		}

		this.validation = this.validation.bind(this)
		this.submitForm = this.submitForm.bind(this)
		this.nextPosition = this.nextPosition.bind(this)
		this.prevPosition = this.prevPosition.bind(this)
		this.onChange = this.onChange.bind(this)
		this.formSubmitted = this.formSubmitted.bind(this)
		this.onCheck = this.onCheck.bind(this)
		this.setCategories = this.setCategories.bind(this)
		this.loadFormData = this.loadFormData.bind(this)
		this.orgChange = this.orgChange.bind(this)
		this.handlePlanChange = this.handlePlanChange.bind(this)
		this.onProductCheck = this.onProductCheck.bind(this)
		this.selectAll = this.selectAll.bind(this)
	}

	componentWillMount() {
		if(typeof(window) !== 'undefined') {
			window.location = 'https://www.flourishchange.com/'
		}
	}

	componentDidMount() {
		this.props.clearErrors()
		this.loadFormData()
		this.props.getCategories()
	}

	formSubmitted() {
		window.location.href = '/thankyou'
	}

	orgChange(e) {
		let data = {}
		data[e.target.name.split('org_')[1]] = ''
		data[e.target.name.split('org_')[1]] =  e.target.value

		if (e.target.name.includes('address_')) {
			data = Object.assign(this.state.organization.address, { [e.target.name.split('org_address_')[1]]: e.target.value })
			data = { 'address': data }
		} else if (e.target.name.includes('phone')) {
			data[e.target.name.split('org_')[1]] = e.target.value.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
		}

		const organization = Object.assign(this.state.organization, data)
		this.setState({ organization })
	}

	onChange(e) {
		if (e.target.name.includes('org_')) {
			this.orgChange(e)
		} else {
			const newData = { [e.target.name]: e.target.value }
			if (e.target.name.includes('phone')) {
				newData[e.target.name] = e.target.value.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
			}
			const admin = Object.assign(this.state.admin, newData)
			this.setState({ admin })
		}


		if (!this.state.firstPress) {
			try {
				/* eslint-disable-next-line */
				analytics.track('Sign Up Form Started')
			} catch (err) {
				// ignore
			}
			this.setState({ firstPress: true })
		}
	}

	onCheck(e) {
		const { consent } = this.state
		this.setState({ consent: { ...consent,  [e.target.name]: e.target.checked } })
	}

	onProductCheck(e) {
		const { products } = this.state
		this.setState({ products: { ...products,  [e.target.name]: e.target.checked } })
	}

	handlePlanChange() {
		this.setState(prevState => ({ price: prevState.price === 2 ? 1 : 2 }))
	}

	validation() {
		const freeTrial = typeof window !== 'undefined' && window.location.href.includes('freetrial')

		const { admin, organization, consent } = this.state
		const phoneRegex = /^\d{3}-\d{3}-\d{4}$/im // eslint-disable-line
		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
		const webRegex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/ // eslint-disable-line
		if (!admin.f_name || !admin.l_name) {
			this.setState({
				errorP1: 'Please enter your first and last name.',
				position: 0
			})
			return false
		} else if (!admin.phone || !phoneRegex.test(admin.phone)) {
			this.setState({
				errorP1: 'Please enter a valid phone number (ie 214-555-5555).',
				position: 0
			})
			return false
		} else if (!admin.email || !emailRegex.test(admin.email)) {
			this.setState({
				errorP1: 'Please enter a valid email.',
				position: 0
			})

			return false
		} else if (!organization.title) {
			this.setState({
				errorP2: 'Please name your organization',
				position: 1
			})

			return false
		} else if (!organization.phone || !phoneRegex.test(organization.phone)) {
			this.setState({
				errorP2: 'Please enter a valid phone number (ie 214-555-5555).',
				position: 1
			})

			return false
		} else if (!organization.email || !emailRegex.test(organization.email)) {
			this.setState({
				errorP2: 'Please enter a valid email',
				position: 1
			})

			return false
		} else if (!organization.website || !webRegex.test(organization.website)) {
			this.setState({
				errorP2: 'Please enter a valid website',
				position: 1
			})

			return false
		} else if (!organization.address.street_address) {
			this.setState({
				errorP3: 'Please enter a street address',
				position: 2
			})

			return false
		} else if (!(organization.address.city && organization.address.state_id && organization.address.zip)) {
			this.setState({
				errorP3: 'Please enter a city, state, and zip',
				position: 2
			})

			return false
		} else if (!organization.EIN) {
			this.setState({
				errorP3: 'Please enter an EIN.',
				position: 2
			})

			return false
		} else if (!organization.mission) {
			this.setState({
				errorP4: 'Please enter a mission statement',
				position: 3
			})

			return false
		} else if (!recaptchaRef.current.getValue()) {
			this.setState({
				errorP4: 'Please enter the Google Captcha',
				position: 4
			})

			return false
		} else if (freeTrial && !(consent.trial_agree && consent.processing_agree)) {
			this.setState({
				errorP5: "Please agree to all of the terms above.",
				position: 4
			})

			return false
		} else if (!consent.authority_agree) {
			this.setState({
				errorP5: "Please agree to all of the terms above.",
				position: 4
			})

			return false
		}

		this.setState({
			error: '',
			valid: true,
		})

		return true
	}

	submitForm(e) {
		e.preventDefault()
		if (this.validation()) {
			const { admin, consent } = this.state

			const organization = Object.assign(this.state.organization, {})

			try {
				/* eslint-disable-next-line */
				analytics.identify(this.state.UUID, {
					firstName: admin.f_name,
					lastName: admin.l_name,
					email: admin.email,
					organization: organization.title,
					phone: admin.phone
				})
				/* eslint-disable-next-line */
				analytics.track('Sign Up Form Submitted')
			} catch (err) {
				// ignore
			}

			organization.address = JSON.stringify(organization.address)

			const data = { organization, admin, consent }
			this.setState({ pending: true })
			this.props.submitOrgRequest(data, ()=>this.formSubmitted())
		}
	}

	nextPosition() {
		this.setState({
			position: this.state.position < 4 ? this.state.position + 1 : this.state.position,
		})


		// try {
		// 	/* eslint-disable-next-line */
		// 	analytics.track(`Sign Up Form Page ${this.state.position + 2} Start`)
		// } catch (e) {
		// 	// ignore
		// }


		// if (this.state.position === 0) {
		// 	const { fname, lname, email, phone } = this.state
		//
		// 	try {
		// 		/* eslint-disable-next-line */
		// 		const UUID = analytics.user().anonymousId()
		// 		this.setState({ UUID })
		//
		// 		/* eslint-disable-next-line */
		// 		analytics.identify(UUID, {
		// 			firstName: fname,
		// 			lastName: lname,
		// 			email: email,
		// 			phone: phone
		// 		})
		// 	} catch (e) {
		// 		// ignore
		// 	}
		// }
	}

	selectAll(key) {
		const { products } = this.state
		Object.keys(products).map(product => {
			products[product] = false
			return false
		})
		products[key] = true

		this.setState({products})
		this.nextPosition()
	}

	prevPosition() {
		const { products } = this.state
		Object.keys(products).map(product => {
			products[product] = false
			return false
		})

		this.setState({
			position: this.state.position > 0 ? this.state.position - 1 : this.state.position,
			products
		})
	}


	loadFormData() {
		const data = getUrlParams()

		const admin = Object.assign(this.state.admin, {
			'f_name': data.firstname,
			'l_name': data.lastname,
			'phone': data.phone,
			'email': data.email
		})
		const organization = Object.assign(this.state.organization, { 'title': data.company })

		this.setState({ organization, admin, price: parseInt(data.annual, 10) || 1 })
	}

	setCategories(categories) {
		this.setState({ categories })
	}

	render() {
		const { coupon } = this.props
		const {oneClickRelationships, analytics, app, marketing}  = whatOffer.content
		const {price} = this.state
		const totalPositions = 2

		const chosenProducts = Object.keys(this.state.products).filter((key) => this.state.products[key])
		const plans = chosenProducts.length > 0 && chosenProducts.map((k) => stripePlans[price][k])
		const subTotal = chosenProducts.length > 0 && chosenProducts.map((k) => Number(whatOffer.prices[price][k])).reduce((a, b)=> a + b, 0)
		var bill = subTotal
		var discount = 0

		if (Object.keys(coupon).length !== 0 ) {
			if (coupon.amount_off) {
				discount = coupon.amount_off / 100
				bill = bill - discount
				discount = `-$${discount}`
			} else if (coupon.percent_off) {
				discount = coupon.percent_off
				bill = bill * (1 - discount / 100)
				discount = `-${discount}%`
			}
		}

		return (
			<Layout>
				<Section bg={oneTimeDonation.bg} paddingTop={'8em'} paddingBottom={'3em'}>
					<form onSubmit={()=>{}}>
						<ScrollableContainer position={this.state.position} totalPositions={totalPositions}>
							<Container maxWidth={'950px'}>
								<Row>
									<Column>
										<CardForm style={{ borderRadius: '0  0 8px 8px', maxHeight: 'none' }}>
											<FormProgress totalPositions={totalPositions - 1} currentPosition={0}/>
											<Row style={{ marginTop: 15 }}>
												<Column style={{flex: 3}}>
													<Title fontSize={'50px'} textAlign={'left'} marginBottom={'0em'} style={{margin: 0, padding: "0px 15px"}}>A plan for everyone.</Title>
													<Paragraph fontSize={'25px'} textAlign={'left'} marginBottom={'0.5em'} style={{padding: "0px 15px"}}>Select the products that meet your needs</Paragraph>
												</Column>
												<div style={{ display: 'flex', flex: 2, justifyContent: 'center', alignItems: 'center' }}>
													<Paragraph style={{ fontWeight: 800, lineHeight: '75px', marginRight: 10 }}>Monthly</Paragraph>
													<Switch
														onChange={this.handlePlanChange} checked={this.state.price === 2}
														onColor={theme.colors.sunglow}
														onHandleColor={theme.colors.crusta}
														handleDiameter={50}
														uncheckedIcon={false}
														checkedIcon={false}
														boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
														activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
														height={30}
														width={100}
														className="react-switch"
														id="material-switch"
													/>
													<Paragraph style={{ fontWeight: 800, lineHeight: '75px', marginLeft: 10 }}>Annual</Paragraph>
												</div>
											</Row>
											<Row style={{ marginLeft: 0, marginRight: 0, marginTop: 25}}>
												<Column style={{ justifyContent: 'center' }} center>
													<Title fontSize="30px" textAlign={'center'} marginTop={0}>One-Click Relationships</Title>
													<div style={{height: 3, backgroundColor: theme.colors.crusta}}/>
												 	<Row style={{flexDirection: 'row'}}>
														 <div style={{flex: 3, alignItems: 'center', }}>
													 		<Title fontSize={'30px'} textAlign={'center'} marginBottom={0} marginTop={0} style={{marginBottom: 0}}>{oneClickRelationships.pricing[price]}</Title>
															<Paragraph
																textAlign={'center'}
																fontSize={'10px'}
																fontWeight={400}
																style={{height: '1.5em'}}>	<a href={'https://storage.googleapis.com/resources.flourishchange.com/Flourish%20Texting%20Pricing.pdf'} target="_blank">volume pricing</a></Paragraph>
														</div>
														<CheckBox
															type="checkbox"
															name={'oneClick'}
															style={{flex: 1}} defaultSelected={this.state.products.oneClick} value={Number(this.state.products.oneClick)} onChange={this.onProductCheck}/>
													 </Row>
												</Column>
												<Column style={{ justifyContent: 'center'}} center>
													<Title fontSize={'30px'} textAlign={'center'} marginTop={0}>Targeted Marketing</Title>
													<div style={{height: 3, backgroundColor: theme.colors.crusta}}/>
													<Row style={{flexDirection: 'row'}}>
														 <div style={{flex: 3, alignItems: 'center', width: '50%'}}>
														 <Title fontSize={'30px'} textAlign={'center'} marginBottom={0} marginTop={0} style={{marginBottom: 0}}>{marketing.pricing[price]}</Title>
															<Paragraph textAlign={'center'}  fontSize={'10px'} fontWeight={400}>{whatOffer.content.marketing.pricing2}</Paragraph>
														</div>
														<CheckBox type="checkbox" name={'marketing'} style={{flex: 1}} defaultSelected={this.state.products.marketing} value={this.state.products.marketing} onChange={this.onProductCheck}/>
													 </Row>


												</Column>
												<Column style={{ justifyContent: 'center'}} center>
													<Title fontSize={'30px'} textAlign={'center'} marginTop={0}>The Flourish App + Dashboard</Title>
													<div style={{height: 3, backgroundColor: theme.colors.crusta}}/>
													<Row style={{flexDirection: 'row'}}>
														 <div style={{flex: 3, alignItems: 'center', width: '50%'}}>
														 <Title fontSize={'30px'} textAlign={'center'} marginBottom={0} marginTop={0} style={{marginBottom: 0}}>{app.pricing[price]}</Title>
														 <Paragraph textAlign={'center'}  fontSize={'10px'} fontWeight={400}>{whatOffer.content.app.pricing2}</Paragraph>
														</div>
														<CheckBox type="checkbox" name={'app'} style={{flex: 1}} defaultSelected={this.state.products.app} value={this.state.products.app} onChange={this.onProductCheck}/>
													 </Row>
												</Column>
												<Column style={{justifyContent: 'center'}}>
													<Title fontSize={'30px'} textAlign={'center'} marginTop={0}>Deep Analytics <sup>1</sup> + Predictions</Title>
													<div style={{height: 3, backgroundColor: theme.colors.crusta}}/>
													<Row style={{flexDirection: 'row'}}>
														 <div style={{flex: 3, alignItems: 'center', width: '50%'}}>
														 <Title fontSize={'30px'} textAlign={'center'} marginBottom={'0.5em'} marginTop={0} style={{marginBottom: 0}}>{analytics.pricing[price]}</Title>
														 <Paragraph textAlign={'center'}  fontSize={'10px'} fontWeight={400} style={{height: '1.5em'}}>{whatOffer.content.analytics.pricing2}</Paragraph>
														</div>
														<CheckBox type="checkbox" name={'analytics'} style={{flex: 1}} defaultSelected={this.state.products.analytics} value={this.state.products.analytics} onChange={this.onProductCheck}/>
													 </Row>
												</Column>
											</Row>
											<Row>
												<Column center style={{marginTop: '2em'}}>
													<BtnField disabled={chosenProducts.length === 0} onClick={this.nextPosition} style={{width: '50%', margin: 'auto'}}>Next</BtnField>
												</Column>
											</Row>
											<Row style={{ marginTop: 15 }}>
												<Column>
													<Title fontSize={'40px'} textAlign={'center'} marginTop={'1em'}>
														Ready for everything?
													</Title>
													<Paragraph fontSize={'25px'} textAlign={'center'} marginBottom={'0.5em'}>
													So excited that you want all our products? Then let's chat!
													</Paragraph>
												</Column>
											</Row>
											<Row>
												<Column maxWidth={'50%'} style={{marginTop: '2em'}} center>
													<Box
														onClick={()=>this.selectAll('monthly')} hoverColor={'rgba(255, 204, 51, 0.8)'}
														style={{ boxShadow: `0 15px 35px 0 ${theme.colors.buttonsShadow}` }}
														bgColor={theme.colors.crusta}
														marginRight={'0'}
														data={{title: '$850/month', paragraph: 'cancel anytime'}} />
													<Column center style={{marginTop: '2em'}}>
														<BtnField onClick={()=>this.selectAll('monthly')} style={{width: '50%', margin: 'auto'}}>Select</BtnField>
													</Column>
												</Column>
												<Column maxWidth={'50%'} style={{marginTop: '2em', marginBottom: '2em'}} center>
													<Box
														onClick={()=>this.selectAll('annual')}
														hoverColor={'rgba(255, 204, 51, 0.8)'}
														style={{ boxShadow: `0 15px 35px 0 ${theme.colors.buttonsShadow}` }}
														bgColor={theme.colors.crusta}
														marginRight={'auto'}
														marginLeft={'0'}
														data={{title: '$8,500/year', paragraph: '2 months free'}}
														action={'Contact Sales'}
													/>
													<Column center style={{marginTop: '2em'}}>
														<BtnField onClick={()=>this.selectAll('annual')} style={{width: '50%', margin: 'auto'}}>Select</BtnField>
													</Column>
												</Column>
											</Row>
										</CardForm>
									</Column>
								</Row>
							</Container>

							<Container maxWidth={'950px'}>
								<Row>
									<Column>
										<CardForm style={{ maxHeight: 'none', marginLeft: 0, marginRight: 0 }}>
											<FormProgress totalPositions={totalPositions - 1} currentPosition={2}/>
											<Row style={{ marginTop: 15, marginLeft: 0, marginRight: 0 }}>
												<Column>
													<Title fontSize={'50px'} textAlign={'center'} marginBottom={'0em'}>One Last Thing...</Title>
													<Paragraph fontSize={'25px'} textAlign={'center'} marginBottom={'0.5em'}>Make sure you have all you need and let's do this!</Paragraph>
												</Column>
											</Row>
											<Column style={{ marginLeft: 0, marginRight: 0}}>
												<Row>
													<Column style={{ justifyContent: 'center' }} center>
														<Title style={{fontSize: 25}} textAlign={'center'} marginTop={0}>Products</Title>
														<div style={{height: 3, backgroundColor: theme.colors.crusta, marginBottom: 10}}/>
														{chosenProducts.map((product) => (
															<Paragraph fontSize={'14px'} textAlign={'center'} marginBottom={0} marginTop={0} style={{marginBottom: 0}}>{whatOffer.products[product]} - ${`${whatOffer.prices[price][product]}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</Paragraph>
														))}
														<div style={{height: 3, backgroundColor: theme.colors.crusta, marginTop: 20, marginBottom: 20}}/>
														{Object.keys(coupon).length !== 0 && <Title style={{fontSize: 25}}>Subtotal</Title>}
														{Object.keys(coupon).length !== 0 && <Title style={{fontSize: 18}}>Discount</Title>}
														{Object.keys(coupon).length !== 0 && <Title style={{fontSize: 18}}>${`${subTotal}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</Title>}
														{Object.keys(coupon).length !== 0 && <Title style={{fontSize: 18}}>{discount}</Title>}
														{(this.state.products.annual || price === 2) ?
															<Title style={{fontSize: 25}}>Total/year: ${`${bill}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</Title>
															: <Title style={{fontSize: 25}}>Total/month: ${`${bill}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</Title>
														}
													</Column>
												</Row>
												<Row style={{ marginTop: 15, marginLeft: 0, marginRight: 0 }}>
													<Column>
														<Title fontSize={'25px'} textAlign={'center'} marginBottom={'0em'}>Checkout</Title>
													</Column>
												</Row>
												<Row style={{ marginLeft: 0, marginRight: 0 }}>
													<Checkout
														plan={plans}
														products={chosenProducts}
														discount={discount}
														bill={bill}
														prevPosition={this.prevPosition}
													/>
												</Row>
											</Column>
											<Row style={{ textAlign: 'center', marginLeft: 0, marginRight: 0, paddingLeft: 10, paddingRight: 10 }}>
												<Paragraph style={{ color: '#4a4a4a', fontSize: 12 }}>By pressing "Submit", I certify that I am the person that can make financial decisions and sign agreements on behalf of my organization and I authorize Flourish Change Inc. to charge the card provided and sign up for the flourish platform
				                        			and all charges that correspond to the service provided as defined in the <Link style={{ display: 'inline' }} href="https://storage.googleapis.com/resources.flourishchange.com/Marketing/Contracts/Flourish_SaaS_agreement.pdf"> terms of service</Link>. I also agree to sign up for a subscription of
																Flourish on a monthly or annual recurring billing cycle.
												</Paragraph>
											</Row>
										</CardForm>
									</Column>
								</Row>
							</Container>
						</ScrollableContainer>
					</form>
				</Section>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	error: PropTypes.any,
	pending: PropTypes.any,
	clearErrors: PropTypes.func,
	submitOrgRequest: PropTypes.func,
	success: PropTypes.bool,
	getCategories: PropTypes.func,
	categories: PropTypes.array,
	coupon: PropTypes.object
}

const mapStateToProps = (state) => {
	const { utilState } = state
	return {
		error: utilState.error,
		pending: utilState.pending,
		success: utilState.success,
		categories: utilState.categories,
		coupon: utilState.coupon,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		clearErrors: () => dispatch(clearErrors()),
		submitOrgRequest: (data, callBack) => dispatch(submitOrgRequest(data, callBack)),
		getCategories: (callBack) => dispatch(getCategories(callBack))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(IndexPage)
