/* eslint-disable */
import {
	STAGING
} from './actionTypes'

export default STAGING() ? {
	1:{
		'monthly': 'plan_FfJMRet4z1qgA9',
		'app': 'plan_EnLpfEnZF5Ij3t',
		'marketing': 'plan_Fkec2Jg4PxgNs1',
		'analytics': 'plan_FfJPddgfWjPbxt',
		'oneClick': 'plan_FfJP04BoJyvl3y',
		'annual': 'plan_FfJQgIGu8AzDx5',
		'grant': 'plan_G89ozyeCpgJGZm',
		'grantPlus': 'plan_G89pcle8msFSb3'
	},
	2: {
		'monthly': 'plan_FfJMRet4z1qgA9',
		'app': 'plan_Fy4lGeHBnYcilu',
		'marketing': 'plan_Fy4lFXmvHycmYQ',
		'analytics': 'plan_Fy4myuIzWkWXNx',
		'oneClick': 'plan_Fy4nojEoKixlgV',
		'annual': 'plan_FfJQgIGu8AzDx5',
		'grantPlus': 'plan_G89pNKyy4nDCXs',
		'grant': 'plan_G89olmD4mvygnN'
	}
} : {
	1: {
		'monthly': 'plan_Ffe6tlGXd2IdYH',
		'annual': 'plan_Ffe6P1vj8A5HbZ',
		'app': 'plan_Ffe5nhH6hCaxF5',
		'marketing': 'plan_FkebTycHsSqOUX',
		'analytics': 'plan_Ffe4a31L43G6Wu',
		'oneClick': 'plan_Ffe2U1zaqA4dau',
		'grant': 'plan_G57RUXXdeibe0J',
		'grantPlus': 'plan_FkebTycHsSqOUX'
	},
	2: {
		'monthly': 'plan_Fy4oIlsAwzZhvZ',
		'annual': 'plan_Ffe6P1vj8A5HbZ',
		'app': 'plan_Fy4wD1hlh52Jmc',
		'marketing': 'plan_Fy4oIlsAwzZhvZ',
		'analytics': 'plan_Fy57WmE2jkdcBc',
		'oneClick': 'plan_Fy57ANK5WgpOt6',
		'grantPlus': 'plan_G89mglGZ87nIZv',
		'grant': 'plan_G89mw0KMOpA3hB'
	}
}
